@import 'src/style/core';

.CreatureCard {
  margin: 5px;

  text-shadow:
        -1px -1px 0 $mnm-gray,  
         1px -1px 0 $mnm-gray,
         -1px 1px 0 $mnm-gray,
          1px 1px 0 $mnm-gray;

  h5 {
    margin: 0;
  }

  .info-container {
    padding: 6px;
    border-radius: 8px;
    width: 200px;
    height: 300px;
    background-size: cover !important;
    opacity: 0.9;
    box-shadow: 2px 2px 4px rgba(99, 99, 99, 0.75);

    * {
      opacity: 1;
      color: $mnm-white;
    }

    .name-container {
      display: flex;
      position: relative;
      justify-content: space-between;
      text-align: left;
      height: 25%;

      .name {
        width: 60%;
        font-size: 22px;
        font-weight: 600;
        line-height: 1.2;
      }

      .heart-container {
        position: relative;

        .heart-button {
          background: none;
          border: none;

          .heart {
            position: relative;
            right: 6px;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            border: solid rgb(20, 20, 20) 1px;
            background-color: rgb(100, 100, 100);

            &::before {
              content: '';
              display: inline-block;
              position: absolute;
              top: -1px;
              left: 11px;
              height:12px;
              width: 12px;
              border-radius: 50%;
              border: solid rgb(20, 20, 20) 1px;
              background-color: rgb(100, 100, 100);
            }

            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              top: 3px;
              left: 4px;
              height: 15px;
              width: 15px;
              transform: rotate(45deg);
              border-radius: 50% 0 0 0;
              border-bottom: solid rgb(20, 20, 20)1px;
              border-right: solid rgb(20, 20, 20) 1px;
              background-color: rgb(100, 100, 100);
            }
          }

          .red, .red::before {
            border: solid white 1px;
            background-color: rgb(200, 0, 0);
          }

          .red::after {
            border-bottom: solid white 1px;
              border-right: solid white 1px;
              background-color: rgb(200, 0, 0);
          }
        }
      }

      .defeat-button {
        float: right;
        margin-bottom: 15px;
        height: 18px;
        background: none;
        box-shadow: 2px 2px 4px rgba(99, 99, 99, 0.75);
      }

      .creature-details {
        text-align: right;

        .id {
          font-family: $font-fam-base;
        }

        .abilities {
          margin-top: 4px;
          line-height: 1.2;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 18px;
      margin: 12px 0 0 5px;

      .action-die-button {
        padding: 0; 
        background: none;
        border: none;
      }

      .dice-roll {
        font-family: Times, serif; 
        display: absolute;
        border: solid black 1px;
        border-radius: 8px;
        color: black;
        font-size: 30px;
        font-weight: 700;
        background-color: rgb(253, 246, 215);
        text-align: center;
        margin: 0 2px;
        height: 35px;
        width: 35px;
        float: right;
      }
    }
  }

  .hidden {
    display: none;
  }

  .fill {
    opacity: 0;
    height: 55px;
  }
}
