@import 'core';

.Footer {
  display: flex;
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: center;
  height: 75px;
  background-color: darken($mnm-tan, 4%);
  box-shadow: 0px -1px 2px rgba(65, 65, 65, 0.2);

  a {
    margin: 0 10px;
    transition: all .3s;
    
    &:hover {
      transform: scale(1.1)
    }

    img {
      height: 40px;

      &:hover {
      }
    }
  }
}
