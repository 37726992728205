@import 'core';

.Nav {
  display: flex;
  height: 75px;

  // NAV MENU
  .nav-menu {
    display: flex;
    position: fixed;
    flex-direction: column;
    width: 0px;
    height: 100vh;
    z-index: 1;
    background-color: $mnm-gray;
    transition: width .3s;

    a {
      position: relative;
      left: -175px;
      margin-top: 25px;
      color: $mnm-white;
      font-size: 24px;
      font-weight: 600;
      text-shadow: 1px 1px 1px $mnm-maroon;
      transition: transform .3s;

      &:hover {
        transform: scale(1.05)
      }
    }
  }
  
  .open {
    width: 175px;

    a {
      left: 0;
    }
  }

  // GRAY OUT APP CONTAINER
  .gray-mobile-nav-background {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: $mnm-gray;
    opacity: .5;
  }

  .mobile-backgound-open {
    display: block;
  }

  // TOP/SIDE BAR
  .nav-bar {
    display: flex;
    width: 100vw;
    background-color: $mnm-maroon;
    height: 75px;
    box-shadow: 0px 1px 2px rgba(65, 65, 65, 0.7);

    .mouse-icon {
      top: 0;
      left: 0;
      padding: 5px;
      height: 65px;
      transition: transform .3s;

      &:hover {
        transform: scale(1.06)
      }
    }
  }

  // HAMBURGER
  .hamburger-button {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px 15px;
    height: 50px;
    background: none;
    border: none;
    

    .hamburger {
      position: relative;
      top: -11px;
      height: 4px;
      width: 40px;
      border-radius: 3px;
      background-color: black;    

      &::before, &::after {
        content: '';
        display: inline-block;
        position: relative;
        height: 4px;
        width: 40px;
        border-radius: 3px;
        background-color: black;
      }

      &::before {
        top: 4px;
      }

      &::after {
        top: 2px;
      }
    }

    .close-x {
      top: 0px;
      right: 0px;
      opacity: .8;
      background-color: $mnm-white;
      transform: rotate(225deg);
      transition: transform .6s ease-in-out;
      

      &::before {
        top: -6px;
        background-color: $mnm-white;
        transform: rotate(90deg);
      }

      &::after {
        display: none;
      }
    }
  }
}

@media (min-width: 600px) {
  .Nav {
    height: 0;
    
    .nav-bar {
      position: fixed;
      width: 150px;
      height: 100vh;
      z-index: -1;

      .mouse-icon {
        padding: 25px;
        height: 100px;
      }
    }

    .nav-menu {
      margin-top: 125px;


      a {
        left: 18px;
        text-shadow: 1px 1px 1px $mnm-gray;
      }
    }

    .hamburger-button {
      display: none;
    }
  }
}
